/* eslint-disable no-unused-vars */
export const DOT_VERSION = process.env._DOT_VERSION;

export const enum SERVICE_VARIANT {
  BASE = 'BASE',
  TV = 'TV',
  FULLTEXT = 'FULLTEXT',
  NANO = 'NANO',
  PARTNER = 'PARTNER',
  SMALL = 'SMALL',
}

export const PROTOCOL = 'https:';
export const API_HOST = 'h.seznam.cz';

export const REGEX_IMEDIA_HOST = /h\.imedia(\.dev\.dszn\.|\.test\.dszn\.|\.)cz/;
export const REGEX_DEV_OR_TEST_HOST = /h\.seznam\.(dev|test)\.dszn\.cz/;

export const enum LOGIN_STATE {
  GREEN = 'GREEN',
  ORANGE = 'ORANGE',
  RED = 'RED',
}

export const LAZY_HITTING_ENABLED = 1;
export const LAZY_HITTING_DISABLED = 0;

export const enum HTTP_METHODS {
  POST = 'POST',
}

// Spenttime
export const FIRST_SPENTTIME_SLOT = 5;
export const SPENTTIME_INTERVAL_CONFIG = [
  {
    endOfInterval: 10,
    interval: FIRST_SPENTTIME_SLOT,
  },
  {
    endOfInterval: 30,
    interval: 20,
  },
  {
    endOfInterval: 4 * 60,
    interval: 30,
  },
  {
    endOfInterval: 15 * 60,
    interval: 60,
  },
  {
    endOfInterval: 60 * 60,
    interval: 5 * 60,
  },
];

// localStorage key for lastSession
export const LAST_SESSION_KEY = 'dot_lastSession';
export const CDL_KEY = 'dot_cdl';

export enum EVENTS {
  AFTER_CFG_EVENT = 'dot-cfg', // event fired when DOT is configured
  COOKIE_EVENT = 'szn:dot:cookie', // CustomEvent name for cookie creation
  GET_INFO_EVENT = 'szn:dot:getinfo', // iframe bridge message event
  GET_INFO_COMPLETE = 'szn:dot:getinfocomplete', // iframe bridge finished info transfer
  REDIRECT_SID = 'szn:dot:getsidfromredirect', // sid after redirect is cut from url
  FIRST_IMPRESS_SENT = 'szn:dot:impressdone', // first impress on page is sent
  PROPAGATE_HIT_MESSAGE = 'szn:dot:propagatehit', // postMessage to send hit data from iframe DOT to the top window DOT
  CDL_DONE = 'szn:dot:cdldone', // cookieDeprecationLabel resolved
  SCMP_CONSENT_SET = 'scmp_consent_set', // CMP event fired after saving consent cookie
}

// cfgFromTopFrame timeout (ms)
export const CFG_FROM_TOP_FRAME_TIMEOUT = 5000;

// debugger
export const SERVICE_NAME = 'DOT';
export const LOG_COLOR_MIN = 0x66;
export const LOG_COLOR_MAX = 0x100;
export const LOG_COLOR_DEFAULT = '#FFF';
export const LOG_COLOR_BG = '#000';

// IS
export const IS_TABLE_KEYS_SPECIAL = {
  rusId: 'rusId',
  said: 'said',
  premium: 'premium',
  loginState: 'state',
};
export const IS_TABLE_LOGIN_STATE = {
  green: 'login',
  red: 'forget',
  orange: 'logout',
};

// Propagate hits
export const PROPAGATE_HITS_BLACKLIST = ['impress', 'load'];

// Additional check for sid request
export const MAX_ITERATIONS = 20;
export const INTERVAL_TIMEOUT = 500;

// Cookie wall
export const CW_REFERRER_COOKIE_NAME = 'cw_referrer';
export const CW_REFERRER_CURRENT_COOKIE_NAME = 'cw_referrer_current';

export const SSP_ALLOWED_SOURCES = {
  'hp_feed': 'hp_feed', // deprecated, but we cannot afford to break websites using this
  'sekce-z-internetu': 'hp_feed',
  'denni-tisk': 'novinky',
  'novinky': 'novinky',
  'media': 'media',
  'z-boxiku': 'hp_box',
  'hp_box': 'hp_box',
  'search': 'search',
  'software': 'software',
  'sbrowser': 'sbrowser',
  'radio': 'radio',
  'tvseznam': 'tvseznam',
} as const;
